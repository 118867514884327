import React, { useEffect, useState } from "react";
import { apiClient, WSMessage } from "./apiClient";
import "./App.css";
import { apiUrl } from "./config";
import Logo from "./Logo";
import { useGoogleAuth } from "./useGoogleAuth";

function AppContent() {
  const [message, setMessage] = useState<string>("");
  const { user, logout } = useGoogleAuth();
  const [imageError, setImageError] = useState(false);
  const [complianceMatrixStatus, setComplianceMatrixStatus] = useState<string | null>(null);
  const [summaryStatus, setSummaryStatus] = useState<string | null>(null);
  const [responseTemplateStatus, setResponseTemplateStatus] = useState<string | null>(null);
  const [wsMessages, setWsMessages] = useState<WSMessage[]>([]);

  useEffect(() => {
    if (user) {
      apiClient
        .getMessage()
        .then((data: { message: string }) => setMessage(data.message))
        .catch((error: Error) => {
          console.error("Error fetching data:", error);
          setMessage("Something seems to be broken.");
        });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      console.log("Connecting WebSocket");
      apiClient.connectWebSocket();

      const unsubscribe = apiClient.onMessage((message: WSMessage) => {
        setWsMessages((prev) => {
          console.log("Got message:", message);

          if (message.type === "status" && message.id) {
            const existingIndex = prev.findIndex((msg) => msg.id === message.id);

            if (existingIndex !== -1) {
              const newMessages = [...prev];
              newMessages[existingIndex] = message;
              return newMessages;
            }
          }

          return [...prev, message];
        });
      });

      return () => {
        unsubscribe();
        apiClient.disconnectWebSocket();
      };
    }
  }, [user]);

  const handleComplianceMatrixGeneration = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const statementOfWork = formData.get("statement_of_work") as File;
    const specialConditions = formData.get("special_conditions") as File;

    if (statementOfWork) {
      try {
        setComplianceMatrixStatus("Creating compliance matrix...");
        const result = await apiClient.createComplianceMatrix(statementOfWork, specialConditions || undefined);
        setComplianceMatrixStatus(result.message);
      } catch (error) {
        console.error("Upload failed:", error);
        setComplianceMatrixStatus(`Upload failed: ${error instanceof Error ? error.message : "Unknown error"}`);
      }
    }
  };

  const handleSummarize = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const statementOfWork = formData.get("statement_of_work") as File;
    const specialConditions = formData.get("special_conditions") as File;

    if (statementOfWork) {
      try {
        setSummaryStatus("Generating summary...");
        const result = await apiClient.summarisePDF(statementOfWork, specialConditions || undefined);
        setSummaryStatus(result.message);
      } catch (error) {
        console.error("Summary failed:", error);
        setSummaryStatus(`Summary generation failed: ${error instanceof Error ? error.message : "Unknown error"}`);
      }
    }
  };

  const handleCreateResponseTemplate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const statementOfWork = formData.get("statement_of_work") as File;
    const responseTemplate = formData.get("response_template") as File;

    if (statementOfWork && responseTemplate) {
      try {
        setResponseTemplateStatus("Creating response template...");
        const result = await apiClient.createResponseTemplate(statementOfWork, responseTemplate);
        setResponseTemplateStatus(result.message);
      } catch (error) {
        console.error("Response template creation failed:", error);
        setResponseTemplateStatus(`Creation failed: ${error instanceof Error ? error.message : "Unknown error"}`);
      }
    }
  };

  const handleLogin = () => {
    window.location.href = `${apiUrl}/auth/google/login`;
  };

  return (
    <div className="h-full min-h-screen bg-ox-white text-ox-black font-sans">
      {!user ? (
        <div className="flex items-center justify-center h-full min-h-screen">
          <button
            onClick={handleLogin}
            className="bg-ox-primary hover:bg-ox-white hover:text-ox-primary border-2 border-ox-primary text-ox-white font-bold py-2 px-4 rounded"
          >
            Continue with Google
          </button>
        </div>
      ) : (
        <>
          <header className="w-full bg-ox-white sticky top-0 left-0 z-10 h-16 p-6">
            <div className="container mx-auto px-4 py-2 flex justify-between items-center">
              <Logo className="w-44 pb-4" />
              <h1 className="text-4xl font-bold">Bid Applications</h1>
              <div className="flex items-center justify-center cursor-pointer" onClick={logout}>
                <p className="mx-4">{user.name}</p>
                <img
                  src={user.picture}
                  alt="User profile"
                  className="mx-auto rounded-full h-10 w-10"
                  onError={() => setImageError(true)}
                  style={{ display: imageError ? "none" : "block" }}
                />
                {imageError && (
                  <div className="mx-auto rounded-full h-10 w-10 bg-gray-300 flex items-center justify-center">
                    <span className="text-gray-600">{user.name[0]}</span>
                  </div>
                )}
              </div>
            </div>
          </header>
          <p className="mt-12 text-2xl text-center">{message || "Loading..."}</p>
          <div className="flex mx-auto max-w-screen-2xl">
            <div className="grid grid-cols-2 w-2/3">
              <div className="mx-8">
                <h2 className="text-2xl font-bold pt-12">Generate Summary</h2>
                <p className="text-gray-700">Upload a PDF and we'll create a summary of its contents.</p>
                <div className="mt-6">
                  <form onSubmit={handleSummarize}>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-medium mb-2">
                        Statement of Work (Required)
                      </label>
                      <input
                        type="file"
                        name="statement_of_work"
                        accept=".pdf"
                        className="p-2 border border-gray-300 rounded w-full text-gray-700"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-medium mb-2">
                        Special Tender Conditions (Optional)
                      </label>
                      <input
                        type="file"
                        name="special_conditions"
                        accept=".pdf"
                        className="p-2 border border-gray-300 rounded w-full text-gray-700"
                      />
                    </div>

                    <button
                      type="submit"
                      className="bg-ox-primary hover:bg-ox-white hover:text-ox-primary border-2 border-ox-primary text-ox-white font-bold py-2 px-4 rounded"
                    >
                      Generate Summary
                    </button>
                  </form>
                  <p className="mt-4">{summaryStatus}</p>
                </div>
              </div>

              <div className="mx-8">
                <h2 className="text-2xl font-bold pt-12">Create Compliance Matrix</h2>
                <p className="text-gray-700">Upload a PDF and we'll create a compliance matrix.</p>
                <div className="mt-6">
                  <form onSubmit={handleComplianceMatrixGeneration}>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-medium mb-2">
                        Statement of Work (Required)
                      </label>
                      <input
                        type="file"
                        name="statement_of_work"
                        accept=".pdf"
                        className="p-2 border border-gray-300 rounded w-full text-gray-700"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-medium mb-2">
                        Special Tender Conditions (Optional)
                      </label>
                      <input
                        type="file"
                        name="special_conditions"
                        accept=".pdf"
                        className="p-2 border border-gray-300 rounded w-full text-gray-700"
                      />
                    </div>

                    <button
                      type="submit"
                      className="bg-ox-primary hover:bg-ox-white hover:text-ox-primary border-2 border-ox-primary text-ox-white font-bold py-2 px-4 rounded"
                    >
                      Create Compliance Matrix
                    </button>
                  </form>
                  <p className="mt-4">{complianceMatrixStatus}</p>
                </div>
              </div>

              <div className="mx-8">
                <h2 className="text-2xl font-bold pt-12">Create Response Template</h2>
                <p className="text-gray-700">Upload a PDF to create a response template.</p>
                <div className="mt-6">
                  <form onSubmit={handleCreateResponseTemplate}>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-medium mb-2">
                        Statement of Work (Required)
                      </label>
                      <input
                        type="file"
                        name="statement_of_work"
                        accept=".pdf"
                        className="p-2 border border-gray-300 rounded w-full text-gray-700"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-medium mb-2">
                        Response Template (Optional)
                      </label>
                      <input
                        type="file"
                        name="response_template"
                        accept=".docx"
                        className="p-2 border border-gray-300 rounded w-full text-gray-700"
                      />
                    </div>

                    <button
                      type="submit"
                      className="bg-ox-primary hover:bg-ox-white hover:text-ox-primary border-2 border-ox-primary text-ox-white font-bold py-2 px-4 rounded"
                    >
                      Create Response Template
                    </button>
                  </form>
                  <p className="mt-4">{responseTemplateStatus}</p>
                </div>
              </div>
            </div>
            <div className="w-1/3 px-4 border-l">
              <h2 className="text-2xl font-bold pt-12">Notifications</h2>
              <p className="text-gray-700">You'll lose your notifications if you refresh the page.</p>
              <div className="mt-6 overflow-y-auto max-h-[calc(100vh-200px)]">
                {[...wsMessages]
                  .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
                  .map((msg, index) => (
                    <div key={index} className="mb-4 p-3 border rounded">
                      {msg.type === "notification" ? (
                        <p className="text-sm text-ox-black">{msg.message}</p>
                      ) : (
                        <>
                          <p className="text-sm text-ox-black font-medium">{msg.event}</p>
                          <p className="text-sm text-ox-black">{msg.filename}</p>
                          <p className="text-sm text-gray-800">{msg.message}</p>
                        </>
                      )}
                      <p className="text-xs text-gray-600">{new Date(msg.timestamp).toLocaleTimeString()}</p>
                    </div>
                  ))}
                {wsMessages.length === 0 && <p className="text-gray-500 italic">No notifications yet.</p>}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function App() {
  return <AppContent />;
}

export default App;
